import React from 'react'
import { Container } from 'react-bootstrap';

const Footer = () => {
    return (
        <footer className="footer-bg text-white text-center py-3">
            <Container>
                <p className="footer-text">&copy; 2024 KSY Solutions. All rights reserved.</p>
            </Container>            
        </footer>
    );
};

export default Footer;