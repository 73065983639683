import React from "react";
import { Container, Row, Col } from 'react-bootstrap';

const Home = () => {
    return (
        <Container fluid className="text-center py-5">
            <Row className="justify-content-center">
                <Col md={8} className="home-section">
                    <h2>Your Gateway to Superior Technology Solutions</h2>
                    <p>Empower Your Business with AI.</p>
                </Col>
            </Row>                    
        </Container>
    );
};

export default Home;