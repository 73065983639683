import React from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';

const Services = () => {
    return (
        <Container id="services" className="my-5">
            <h2 className="text-center mb-4">Our Services</h2>
            <Row className="card-deck">
                <Col md={3}>
                    <Card className="mb-4 content">
                        <Card.Body>
                            <Card.Title>IT Solutions</Card.Title>
                            <Card.Text>Comprehensive IT services to keep your business running smoothly</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="mb-4 content">
                        <Card.Body>
                            <Card.Title>Software Development</Card.Title>
                            <Card.Text>Custom software solutions tailored to your business needs.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="mb-4 content">
                        <Card.Body>
                            <Card.Title>Automation</Card.Title>
                            <Card.Text>Streamline your processes with our automation services.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="mb-4 content">
                        <Card.Body>
                            <Card.Title>Consultation</Card.Title>
                            <Card.Text>Expert advice to help you navigate the tech landscape.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>        
    );
};

export default Services;